import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from "react-cookie";

//IE compatibilité
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';


import './sass/reset.scss'
import './sass/global.scss'
import './sass/font.scss'
import './sass/style_mob.scss'
import './sass/style.scss'


import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <CookiesProvider>
        <App />
    </CookiesProvider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
