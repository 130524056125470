import React, { useContext } from 'react';
import {Switch, Redirect} from 'react-router-dom';
import RouteWrapper from './Route';

import Home from '../pages/home';

export default function Routes() {

    return(
      <Switch>
        <RouteWrapper path="/" exact component={Home} />
      </Switch>
    );

}