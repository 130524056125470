import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Layout from './component/_Layout';
import history from './component/services/history';

class App extends React.Component {

	constructor(props){
		super(props)
	}

	render(){
	  return (

		<Router history={history}>
			<Layout/>
		</Router>
	  );
	}
}

export default App;
