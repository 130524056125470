import React, { useState, useRef, useCallback } from 'react';
import {Container, Form} from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import CustomField from '../form/CustomField';
import * as Helper from '../form/form_inscription_helper.jsx';
import * as Pattern from '../form/form_pattern_helper.jsx';
import data_dotation from "../../file/dotation.json";
import md5 from 'md5';
import { Cookies } from "react-cookie";
import { BrowserView, MobileView } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';

const cookies = new Cookies();

const Gain = (props) => {
    let code = null
    let type = 0;

    {data_dotation.dotations.map((dot, key) => {
        if (props.code.includes(dot.code)) {
            code = dot
        }
    })}

    let link = 'https://www.onatera.com/FR/fr?utm_campaign=pageconfirmation&utm_source=microsite&utm_medium=microsite';

    if (props.code.includes('NATURO')) {
        type = 1;
    } else if (props.code.includes('ONAT')) {
        type = 2;
    } else if (props.code.includes('SERUM') || props.code.includes('PEAU') || props.code.includes('CUISINE') || props.code.includes('GLOW')) {
        type = 3;
        if (props.code.includes('SERUM')) {
            link = 'https://www.onatera.com/FR/fr/produit-serum-anti-age-hydratant-2-5-acide-hyaluronique-certifie-bio-30-ml-autenti-care?utm_campaign=pageconfirmation&utm_source=microsite&utm_medium=microsite';
        } else if (props.code.includes('PEAU')) {
            link = 'https://www.onatera.com/FR/fr/produit-coffret-3-hydrolats-peaux-delicates-3-flacons-terraia?utm_campaign=pageconfirmation&utm_source=microsite&utm_medium=microsite';
        } else if (props.code.includes('CUISINE')) {
            link = 'https://www.onatera.com/FR/fr/produit-coffret-3-hydrolats-en-cuisine-3-flacons-terraia?utm_campaign=pageconfirmation&utm_source=microsite&utm_medium=microsite';
        } else if (props.code.includes('GLOW')) {
            link = 'https://www.onatera.com/FR/fr/produit-box-glow-in-out-1-unite-autenti-care?utm_campaign=pageconfirmation&utm_source=microsite&utm_medium=microsite';
        }
    }

    setTimeout(() => {
        props.callBack()
    }, 2000)

    return(
        <div className={`gain-container ${props.showParrainage ? "gain-container-left" : ""}  ${props.fadeInGain ? "show" : ""}  `}>
            <img src="/images/desktop/boules.png" className="d-none d-lg-block boules"/>
            <div className="gain-wrapper">
                <div className="gain-title">
                    Félicitations !
                </div>
                <div className="gain-subtitle">
                    Vous remportez
                </div>
                {code != null && <img src={code.imagewin} className="gain-img"/>}
                {type === 0 &&
                    <div className="gain-text">
                        Pour obtenir votre cadeau, veuillez ajouter votre code personnel et unique <b>{props.code}</b> dans la section “Avantages panier” lors de votre prochaine commande sur <a href="https://www.onatera.com/FR/fr?utm_campaign=pageconfirmation&utm_source=microsite&utm_medium=microsite" target="_blank">onatera.com</a> avant le 30 janvier 2023.
                        <br/>
                        Votre cadeau rejoindra alors les autres articles de votre colis !
                    </div>
                }
                {type === 1 &&
                    <div className="gain-text">
                        Pour profiter de votre cadeau, veuillez nous envoyer un email à <a href="mailto://crm@onatera.com" target="_blank">crm@onatera.com</a> en nous indiquant votre code personnel et unique <b>{props.code}</b> et le jour et l’heure souhaités pour votre séance de naturopathie par téléphone avec Christophe Drezet, notre expert naturopathe.
                        <br/>
                        Une fantastique occasion de (re)découvrir les bienfaits de la naturopathie, sans aucune obligation d’achat !
                    </div>
                }
                {type === 2 &&
                    <div className="gain-text">
                        Pour obtenir votre cadeau, veuillez ajouter votre code personnel et unique <b>{props.code}</b> dans la section “Avantages panier” lors de votre prochaine commande sur <a href="https://www.onatera.com/FR/fr?utm_campaign=pageconfirmation&utm_source=microsite&utm_medium=microsite" target="_blank">onatera.com</a> avant le 30 janvier 2023.
                        <br/>
                        Une fantastique occasion de découvrir les univers d’Onatera, sans minimum d’achat !
                    </div>
                }
                {type === 3 &&
                    <div className="gain-text">
                        Pour obtenir votre cadeau, veuillez ajouter le produit directement dans votre panier sur <a href={link} target="_blank">onatera.com</a> avant le 30 janvier 2023.
                        En rentrant votre code personnel et unique <b>{props.code}</b> dans la section “Avantages panier”, le produit passera à 0€.
                        <br/>
                        Une fantastique occasion de découvrir les univers d’Onatera, sans minimum d’achat !
                    </div>
                }

            </div>
            <BrowserView>
                {props.showParrainage &&
                    <Parrainage moveLeft={props.moveLeft} callBack={props.trackSponsor} lastname={props.lastname} firstname={props.firstname} />
                }
            </BrowserView>
            <MobileView>
                <Parrainage moveLeft={props.moveLeft} callBack={props.trackSponsor} lastname={props.lastname} firstname={props.firstname} />
            </MobileView>
        </div>
    )
}

const Parrainage = (props) => {

    return(
        <div className={`parrainage-container ${props.moveLeft ? "move-left" : ""}`}>
            <div className="parrainage-title">
                Tentez aussi de gagner
            </div>
            <img src="/images/desktop/cosmetique.png" className="cosmetique"/>

            <div className="parrainage-subtitle">
                en faisant jouer vos proches
            </div>
            <div className="parrainage-text">
                Plus vous invitez d'amis<br/>
                plus vous multipliez vos chances<br/>
                au tirage au sort !
            </div>
            <ParrainageForm callBack={props.callBack} lastname={props.lastname} firstname={props.firstname} />
            <ParrainageForm callBack={props.callBack} lastname={props.lastname} firstname={props.firstname} />
            <ParrainageForm callBack={props.callBack} lastname={props.lastname} firstname={props.firstname} />

            <div className="parrainage-or">
                ou
            </div>
            <a className="cta black" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.jeunoel-onatera.fr%2F" target="_blank">
                Je partage sur Facebook
            </a>
        </div>
    )
}

const ParrainageForm = (props) => {

    const { register, handleSubmit, errors, setError, clearError } = useForm({validateCriteriaMode: "all"});
    const submitRef = useRef(null);
    const checkmark = useRef(null);

    //--------------------
    //form validation
    //--------------------

    const onSubmit = data => {

        submitRef.current.setAttribute("disabled","disabled")
        submitRef.current.classList.add("hide")
        checkmark.current.classList.add("show")

        let formData = new FormData();
        data.lastname = props.lastname;
        data.firstname = props.firstname;

        formData.append('sponsor',JSON.stringify(data));


        fetch(process.env.REACT_APP_API_URL+'/submitSponsor.php',
            {
                method : 'POST',
                body : formData
            })
            .then(response => response.json())
            .then(json =>
            {
                if(json.success === true){
                    window.scrollTo(0, 0)
                    props.callBack(data.mail)
                }else{
                    submitRef.current.removeAttribute("disabled")
                }
            });
    };
    return(
        <Form onSubmit={handleSubmit(onSubmit)} method="POST" noValidate>

            <CustomField name="mail" placeholder="Email d'un ami" className="input-email-friend"
                         register={
                             register({
                                 required : false,
                                 pattern : Pattern.emailPattern,
                                 validate : {
                                     domain : (value) => Helper.checkMailDomain(value)
                                 }})}
                         error={[
                             errors.mail && errors.mail.type === "required" && Helper.EmptyErrorMessages.mail ,
                             errors.mail && errors.mail.type === "pattern" && Helper.InvalidErrorMessages.mail,
                             errors.mail && errors.mail.type === "domain" && Helper.InvalidErrorMessages.mail_domain,
                         ]}/>
            <button ref={submitRef} type="submit" className="cta-parrainage"> > </button>
            <img ref={checkmark}  src="/images/desktop/checkmark.png" className="checkmark" />
        </Form>
    )
}

const ParticipationForm = (props) => {

    const [show, setShow] = useState(false);
    const { register, handleSubmit, errors, setError, clearError } = useForm({validateCriteriaMode: "all"});
    const submitRef = useRef(null);

    //--------------------
    //form validation
    //--------------------
    const onSubmit = data => {

        submitRef.current.setAttribute("disabled","disabled")

        //envoi du formulaire
        let formData = new FormData();
        formData.append('form',JSON.stringify(data));


        fetch(process.env.REACT_APP_API_URL+'/submitInscriptionForm.php',
            {
                method : 'POST',
                body : formData
            })
            .then(response => response.json())
            .then(json =>
            {
                if(json.success === true){
                    window.scrollTo(0, 0)
                    props.callBack(json.user, data.mail, data.nom, data.prenom, data.optin_newsletter)
                }else{
                    document.getElementById("inscription_global_error").innerHTML = json.error
                    submitRef.current.removeAttribute("disabled")
                }

            });
    };

    return(
		<Form onSubmit={handleSubmit(onSubmit)} id="inscription_form" method="POST" noValidate>

            <div className="form-title">
                Veuillez remplir le formulaire <br className="d-none d-lg-block" />pour participer
            </div>

			<CustomField name="prenom" placeholder="Prénom" register={register({ required: { value : true, message : Helper.EmptyErrorMessages.prenom }, pattern: { value : Pattern.alphaPattern, message : Helper.InvalidErrorMessages.prenom} })} error={errors.prenom && errors.prenom.message}/>
            <CustomField name="nom" placeholder="Nom" register={ register({ required: { value : true, message : Helper.EmptyErrorMessages.nom }, pattern: { value : Pattern.alphaPattern, message : Helper.InvalidErrorMessages.nom} })} error={errors.nom && errors.nom.message}/>
            <CustomField name="mail" placeholder="Adresse e-mail"
						 register={
                             register({
                                 required : true,
                                 pattern : Pattern.emailPattern,
                                 validate : {
                                     domain : (value) => Helper.checkMailDomain(value)
                                 }})}
						 error={[
                             errors.mail && errors.mail.type === "required" && Helper.EmptyErrorMessages.mail ,
                             errors.mail && errors.mail.type === "pattern" && Helper.InvalidErrorMessages.mail,
                             errors.mail && errors.mail.type === "domain" && Helper.InvalidErrorMessages.mail_domain
                         ]}/>
            <div className="optin_wrapper">
				<div className="checkbox_wrapper">
					<label htmlFor="optin_newsletter">
                        <input type="checkbox" name="optin_newsletter" id="optin_newsletter" value="1" ref={ register()}/>
                        Je souhaite recevoir les communications d'Onatera (offres, conseils et décryptages produits). Vous pourrez vous désabonner à tout moment depuis la newsletter.
					</label>
					<div className="error text-center">{errors.optin_newsletter && Helper.EmptyErrorMessages.optin_newsletter}</div>
				</div>
			</div>

			<div id="inscription_global_error" className="text-center mt-5 mt-lg-3 error"/>

			<div className="text-center">
				<button ref={submitRef} type="submit" className="cta black cta-inscription">Je participe</button>
			</div>
		</Form>
    )
}

class Home extends React.Component{

    constructor(props){
        super(props);

        this.starImage = React.createRef();

        this.state = {
            account_created : false,
            dotation_selected: false,
            animation_started: false,
            showParrainage: false,
            moveLeft: false,
            dotation: 0,
            particules: true,
            interrupteurOn: false,
            track: false,
            lastname: '',
            firstname: '',
            email: '',
            optin: false,
            ip: '',
            images: [],
            fadeInGain: false,
            user: null,
            code: '',
            utm_source: null
        }

        this.startAnimation = this.startAnimation.bind(this)
        this.showParrainage = this.showParrainage.bind(this)
        this.stopAnimation = this.stopAnimation.bind(this)
    }

    showParrainage() {
        this.setState({showParrainage: true})

        setTimeout(() => {
            this.setState({moveLeft: true})
        }, 50)

    }

    sendRegistrationTracking = (code, dotation, utm_source) => {
        let source = "direct";
        if (utm_source != null && utm_source != undefined && utm_source != ''){
            source = utm_source
        }

        let optin = 0;
        if (this.state.optin){
            optin = 1;
        }
        let formData = new FormData();
        formData.append('form', JSON.stringify({
            email: this.state.email,
            lastname: this.state.lastname,
            firstname: this.state.firstname,
            custom1: optin,
            custom2: code,
            custom3: dotation,
            custom4: source,
            ip: this.state.ip
        }));

        fetch(process.env.REACT_APP_API_URL+'/trackRegister.php',
            {
                method : 'POST',
                body : formData
            })
            .then(response => response.json())
            .then(json =>
            {
            });
    };

    sendSponsorTracking = (email) => {
        let formData = new FormData();
        formData.append('form', JSON.stringify({email: email, ip: this.state.ip}));

        fetch(process.env.REACT_APP_API_URL+'/trackSponsor.php',
            {
                method : 'POST',
                body : formData
            })
            .then(response => response.json())
            .then(json =>
            {
            });
    };

    startAnimation() {
        let time = 0;
        let interval = setInterval(() => {
            if (time > 124) {
                time = 0;
            }
            this.starImage.current.src = this.state.images[time].url
            time += 1;
        }, 100)
        this.stopAnimation(interval);

        if (!this.state.dotation_selected) {
            this.setState({interrupteurOn: true}, () => {
                if (!this.state.animation_started) {
                    this.setState({animation_started: true}, () => {

                        const data = {
                            user: this.state.user,
                        }
                        let formData = new FormData();
                        formData.append('dotation', JSON.stringify(data));

                        fetch(process.env.REACT_APP_API_URL + '/submitBoosterCode.php',
                            {
                                method: 'POST',
                                body: formData
                            })
                            .then(response => response.json())
                            .then(json => {
                                if (json.code != null && json.code != '') {
                                    window.track(md5(this.state.email))
                                    this.sendRegistrationTracking(json.code, json.dotation, this.state.utm_source);

                                    let code = json.code

                                    this.setState({
                                        code: code,
                                        dotation_selected: true,
                                    });
                                }
                            });
                    })
                }
            })
        }
    }

    checkOpeStop = () => {
        if( ! ("REACT_APP_DATE_OPE_STOP" in process.env) )
            return false

        //let start_date_string = "05/06/2020".split("/");
        let stop_date_string = process.env.REACT_APP_DATE_OPE_STOP.split("/")
        let stop_date = new Date(stop_date_string[2],stop_date_string[1]-1,stop_date_string[0])
        let now_date = new Date()

        return (now_date >= stop_date)
    }

    async componentDidMount(){
        const response = await fetch('https://geolocation-db.com/json/');
        const data = await response.json();
        this.setState({ ip: data.IPv4 })

        const { utm_source } = queryString.parse(this.props.location.search)
        this.setState({utm_source: utm_source})

        setInterval(() => {
            if (this.state.dotation >= data_dotation.dotations.length - 1){
                this.setState({dotation: 0})
            }else {
                this.setState({dotation: this.state.dotation + 1})
            }
        }, 1000)


        let images = []
        for (let i = 1; i < 125; i++) {
            let frame = i;
            if (i < 10) {
                frame = '0000' + i;
            } else if (i < 100) {
                frame = '000' + i;
            } else {
                frame = '00' + i;
            }
            images.push({
                url: '/images/particules-min/Paticules_' + frame + '-min.png'
            })
        }
        this.setState({
            images: images
        });
    }

    stopAnimation(interval) {
        setTimeout(() => {
            this.setState({
                particules: false,
                animation_started: false
            });
            clearInterval(interval)

            setTimeout(() => {
                this.setState({
                    fadeInGain: true,
                });
            }, 100);
        }, 6000);
    }

	render(){

		return(
			<>
            <div className={`felicitation ${this.state.account_created ? "show" : ""} ${this.state.dotation_selected && !this.state.animation_started ? "light-on" : ""}`}></div>
            {!this.state.account_created &&
                <img src="/images/desktop/neige.png" className="neige"/>
            }
            <img src="/images/desktop/logo2.png" className="logo"/>
            {!this.checkOpeStop() && !this.state.account_created &&
				<Container id="home">
                    <div className="subtitle">Du 01 au 22 Décembre 2022</div>
                    <div className="secondary_title">Jeu de Noël</div>

                    <div className="home-container">
                        <div className="gagnant">100&#37; gagnant</div>
                        <img src="/images/desktop/boules.png" className="boules"/>
                        <div className="home-description">
                            <div className="home-description-title">
                                Jouez avec Onatera
                            </div>
                            <div className="home-description-fun">
                                et gagnez à tous les coups !
                            </div>
                            <div className="home-description-subtitle">
                                Découvrez vite votre cadeau à l'étape suivante...
                            </div>
                            <div className="home-description-cadeaux">
                                <span>1 an de cosmétiques solides</span>
                                <span>Coffret DIY</span><br/>
                                <span>Box Glow</span>
                                <span>Séance de naturopathie</span><br className="d-block d-lg-none" />
                                <span>Kit détox</span><br className="d-none d-lg-block" />
                                <span>Coffret hydrolat</span>
                                <span>Sérum anti-âge</span><br/>
                                <span>Huile démaquillante</span><br className="d-none d-lg-block" />
                                <span>Remise de 15%</span>

                                <img src={data_dotation.dotations[this.state.dotation].image} className="produits"/>

                            </div>
                        </div>
                        <ParticipationForm callBack={this.nextStep = (userId, email, lastname, firstname, optin) => {
                            this.setState({
                                account_created : true,
                                user: userId,
                                email: email,
                                lastname: lastname,
                                firstname: firstname,
                                optin: optin
                            })
                        }}/>
                    </div>
                </Container>
            }
            {this.checkOpeStop() &&
            <Container id="home-end">
                <div className="title">Le Jeu Onatera est désormais terminé.<br/>Merci à tous pour votre participation.</div>
            </Container>
            }
            {!this.checkOpeStop() && !this.state.account_created &&
                <>
                    <img src="/images/desktop/sapin.png" className="sapin d-none d-lg-block"/>
                    <img src="/images/desktop/cadeaux.png" className="cadeaux d-none d-lg-block"/>
                    <img src="/images/mobile/sapin.png" className="sapin d-block d-lg-none"/>
                    <img src="/images/mobile/cadeaux.png" className="cadeaux d-block d-lg-none" />
                </>
            }

            {!this.checkOpeStop() && this.state.account_created &&

            <Container id="dotation" onClick={this.startAnimation}>

                {this.state.particules &&
                    <div className="dotation-container">
                        <div className="question-container">
                            Cliquez sur l'interrupteur pour diffuser la magie de Noël et découvrez votre cadeau !
                        </div>
                        {!this.state.interrupteurOn &&
                            <img src="/images/desktop/interrupteur.png" className="dotation-img"/>
                        }
                        {this.state.interrupteurOn &&
                            <img src="/images/desktop/interrupteur-on.png" className="dotation-img"/>
                        }
                        </div>
                }
                {!this.state.showParrainage &&
                <div id="myVideo"
                     className={`${this.state.animation_started ? "video-fade-in" : ""} ${this.state.dotation_selected && !this.state.animation_started ? "video-fade-out" : ""}`}>
                    <img src="" ref={this.starImage} id="video-id"/>
                </div>
                }
                {this.state.dotation_selected && !this.state.animation_started &&
                    <Gain code={this.state.code.replaceAll('"', '')} showParrainage={this.state.showParrainage} moveLeft={this.state.moveLeft} callBack={this.showParrainage} trackSponsor={this.sendSponsorTracking} lastname={this.state.lastname} firstname={this.state.firstname} fadeInGain={this.state.fadeInGain}/>
                }
            </Container>
            }
			</>
		);
	}
}

export default Home;
