import React from 'react';
import {Container} from 'react-bootstrap';

const Footer = () => {

	return(
		<footer>
			<a href="/file/Reglement-jeu-onatera.pdf" target="_blank">Règlement</a>
			<a href="https://www.onatera.com/FR/fr/info/mentions-legales" target="_blank">Mentions légales</a>
		</footer>
	);
};

export default Footer;
