import React from 'react';
import { Row, Col, Form} from 'react-bootstrap';

//champs texte classique
const CustomField = ( {label, name, id=name, placeholder, type="text", register, error, value=""} ) =>{

	return(
		<Form.Group as={Row}>
			<Col xs={12} lg={12} className="">
				
				{value === "" &&
					<Form.Control type={type} id={id} name={name} placeholder={placeholder} ref={register}/>
				}

				<div className="error">{error}</div>
			</Col>
		</Form.Group>
	);
}

export default CustomField;