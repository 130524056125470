//message d'erreur
export let EmptyErrorMessages = {
	prenom 				: "Veuillez saisir votre prénom",
    nom 				: "Veuillez saisir votre nom",
	mail 				: "Veuillez saisir votre adresse e-mail",
	optin_reglement		: "Veuillez lire et accepter le règlement du jeu",
};

export let InvalidErrorMessages = {
    prenom 				: "Veuillez saisir un prenom correct",
	nom 				: "Veuillez saisir un nom correct",
	mail 				: "Veuillez saisir une adresse e-mail correcte",
	mail_domain 		: "Les emails jetable ne sont pas autorisés",
};

//------------------------
//	on change field mail
//------------------------
export function checkMailConfirm(){

	let mail = document.getElementById("mail").value
	let mail_confirm = document.getElementById("mail_confirm").value

	//check de la correspondance des deux emails
	if(mail.length !== 0 && mail_confirm.length !==0 && mail !== mail_confirm)
		return false

	return true;
}

export function checkMailDomain(value){

	let domain = value.substring(value.indexOf('@') + 1)
	let forbiddenMailDomain = ['yopmail.com', 'clipmail.eu', 'clrmail.com', 'get2mail.fr', 'mail-temporaire.fr', 'spamfree24.org', 'jetable.org', 'lala.fr'];

	//check de l'extension du mail
	return(!forbiddenMailDomain.includes(domain));
	//setError(name, "error", InvalidErrorMessages.mail_domain);
}
