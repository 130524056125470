import React from 'react';
import { withRouter } from 'react-router-dom';

import Routes from './routes';

import Footer from "./footer"

class Layout extends React.Component {

  constructor(props){
    super(props);
    this.last_route = "";
  }

  render(){

    let route = this.props.location.pathname.split("/")[1]
    route = route==="" ?"index":route

    if(this.last_route !== "" && route !== this.last_route)
      window.scrollTo(0, 0)

    this.last_route = route;

    return (
        <div className="page_wrapper" id={"page_"+route+"_wrapper"}>
            <div className="content_wrapper" id={"content_"+route+"_wrapper"}>
                <Routes/>
            </div>
          <Footer/>
        </div>
    );

  }
}

export default withRouter(Layout);
